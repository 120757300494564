<template>
  <div style="margin-top: 50px">
    <iframe v-if="!showLoader && link" style="width: 100%; height: 600px" :src="link"></iframe>
    <div style="text-align: center; width: 100%" v-if="!showLoader && !link">Something Went Wrong</div>
    <div v-if="showLoader" style="text-align: center; width: 100%">
      <b-spinner style="width: 3rem; height: 3rem;" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  data() {
    return {
      link: '',
      showLoader: true
    }
  },
  methods: {
    async dataList() {
      const contracts = this.$route.query.contracts;
      const offers = this.$route.query.offers;
      const offersArr = offers.split(',');
      let currentOffer = '';
      let nextLonId = '';
      if (contracts) {
        const arr = contracts.split(',');
        const index = arr.indexOf(this.$route.params.id);
        if (index >= 0 && (index + 1) <= arr.length) {
          nextLonId = arr[index + 1];
          currentOffer = offersArr[index];
        }
      }
      let url = `${process.env.VUE_APP_BASE_URL}/getesignatureurl?token=${this.$route.query.token}&loan_id=${this.$route.params.id}&contracts=${this.$route.query.contracts}&current_offer=${currentOffer}&offers=${offers}`;
      if (nextLonId) {
        url += `&next_loan_id=${nextLonId}`;
      }
      try {
        const response = await axios.get(url);
        if (response && response.data) {
          const company = response.data.companyInfo
          await this.setCompanyInfo(company)
          return response.data.value;
        }
      } catch (e) {
        const company = e.response.data.companyInfo
        await this.setCompanyInfo(company)
      }
      return null;
    },
    async setCompanyInfo(company) {
      const language = this.$route.query.language;
      this.$root.$emit('on-set-logo',
          {
            logo: company.logo,
            contactUs: company.contact_us_link,
            homepage_link: company.homepage_link,
            language: language
          });
    }
  },
  async mounted() {
    this.showLoader = true
    this.link = await this.dataList();
    this.showLoader = false
  }
}

</script>
